// master scss
.flex{
    display: flex;
    display: -webkit-flex;
}

.flex-center-center{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: -webkit-column;
    justify-content: -webkit-center;
    align-items: -webkit-center;
    
}

.flex-space-bw{
    justify-content: space-between;
    justify-content: -webkit-space-between;
}

.flex-wrap{
    flex-wrap:  wrap;
}

.inline-row{
    display: inline-block;
    position: relative;
}

.font-large{
    font-size: 24px;
}
.w100{
    width: 100%;
}

.font-small{
    font-size: 14px;
}

.font-w-large{
    font-weight: 700;
}
// master scss

.intermediate-step{
    width: 100%;
    margin-top: 20px;
    overflow: auto;

    .intermediate-container{
        
        width: 80%;
        height: auto;
        border-radius: 10px;
        padding: 10px;
    }
}

.button-container{
    padding: 10px;
    background-color: #E4824F;
    border-radius: 5px;
    width: 45%;
    cursor: pointer;
    color: #fff;
}
.button-container:nth-of-type(2){
        background-color:  #6FB552;
        
}

.inter-foot{
    position: absolute !important;
    bottom: 0 !important;
    padding: 20px !important;
}

@media only screen and (max-width: 768px)  {
    .intermediate-step{
        .intermediate-container{
            width: 85%;
        }
    }

    .font-large{
        font-size: 18px;
    }

    .button-container{
        width: 100%;
    }
    .button-container:nth-of-type(2){
        margin-top: 10px;
    }
    .font-w-large{
        font-weight: 550;
    }
}