.flex{
    display: flex;
}

.flex-center{
    justify-content: center;
}

.flex-center-center{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.w100{
    width: 100%;
}

.selectYourBudget{
    .ant-slider-handle {
        width: 20px;
        height: 20px;
        top: 1px;
        border: none;
        background-image: url("./assets/price.svg");
        background-size: cover;
      }
}

#stepDiv svg {
    color: rgba(218, 223, 225, 1);
}